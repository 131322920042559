body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-select {
  user-select: none;
}

/* MODAL CUSTOM CSS*/

.ant-modal-content {
  height: 100% !important;
}

.ant-modal-header {
  background: #5584ac !important;
}
.ant-modal-title {
  color: white !important;
  font-size: 18px !important;
}

.ant-modal-close-x {
  color: white !important;
  font-weight: bold !important;
}


.disease-tabs .ant-tabs-nav-list {
  width: 100% !important;
}

.disease-tabs .ant-tabs-tab {
  min-width: calc(100% / 6) !important;
  padding: 0 !important;
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
  margin-left: 0 !important;
}

.disease-tabs .ant-tabs-tab-active {
  background: white !important;
}

.disease-tabs .ant-tabs-content {
  background: white;
}

.disease-tabs .ant-tabs-nav {
  margin: 0 !important;
}

.disease-tabs .ant-tabs-tab-btn {
  width: 100%;
}

.disease-tabs .ant-tabs-nav-more {
  display: none;
}


::-webkit-scrollbar {
  cursor: pointer;
  background: #EFEFEF;
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background: #839AA8;
  border-radius: 8px;
  transition: 0.2s linear;
  padding: 0;
  margin: 0;
}
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  padding: 0;
  margin: 0;
  background: #839AA8;
}

/*
**  For split pane
 */

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
  border-color: #5584ac;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
  border-color: #5584ac;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

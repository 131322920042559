@import "src/styles/variables";

.Container{
  position: relative;
  padding: 30px;
}

.SpinWrap {
  position: absolute;
  top: 50%;
  right: 50%;
}

.ActionWrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.NewUserButton {
  font-size: 15px;
}

.Tab {
  :global {
    .ant-tabs-nav {
      margin: 0;
    }
    .ant-tabs-tab {
      border: 1px solid #BACDDB !important;
      font-weight: bold;
      color: var(--color-main) !important;
      background: #F0F0F0 !important;
    }

    .ant-tabs-tab-btn {
      color: var(--color-main) !important;
    }

    .ant-tabs-tab-active {
      background: #BACDDB !important;
    }

    .ant-tabs-tabpane {
      padding: 5px;
      border: 1px solid #BACDDB !important;
      border-bottom: 0 !important;
      background: #BACDDB;
    }
  }

}

@media only screen and (max-width: $size-large-phone) {
  .Container {
    padding: 10px;
  }
}
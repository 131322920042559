@import "src/styles/variables";

.ContentItem {
  min-height: 100px;
  border: 2px solid;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  transition: all .3s;
  background: #F7F5F2;
  border-color: #DFDFDE;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.IsDefault {
  display: flex;
}

.ContentItemWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Score {
  margin: 0 10px;
  font-weight: bold;
  font-size: 24px;
}

.TextWrap {

}

.Description {
  font-size: 16px;
  color: #7F7C82;
  font-weight: 400;
}

.CopyWrap {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.CopyText {
  font-size: 16px;
  margin-right: 10px;
}

.CopyIconWrap {
  display: flex;
  margin-bottom: 4px;
}

.CopyIcon {
  margin-right: 5px;
  img {
    width: 40px;
  }
}

.EditIconWrap {
  position: absolute;
  right: 3px;
  top: 3px;
  background: #6998AB;
  padding: 4px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}

.EditIcon {
  font-size: 15px !important;
  color: white !important;
  font-weight: bold !important;
}

@media only screen and (max-width: $size-medium-tablet) {
  .CopyWrap {
    display: block;
  }
  .CopyText {
    font-size: 12px;
    margin-right: 10px;
  }

  .Title {
    font-size: 15px;
  }

  .Description {
    font-size: 12px;
  }

  .CopyIcon {
    img {
      width: 36px;
    }
  }
}
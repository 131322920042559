@import "src/styles/variables";

.Container{
  position: relative;
  padding: 30px;
}

.SpinWrap {
  position: absolute;
  top: 50%;
  right: 50%;
}

.ActionWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.NewUserButton {
  font-size: 15px;
}

.Select {
  width: 250px;

  div {
    border-radius: 20px !important;
  }
}

@media only screen and (max-width: $size-large-phone) {
  .Container {
    padding: 10px;
  }

  .ActionWrap {
    display: block;
  }

  .NewUserButton {
    margin-top: 5px;
  }

  .Select {
    width: 100%;
  }

  .ButtonWrap {
    display: flex;
    justify-content: flex-end;
  }
}
@import "src/styles/variables";

.ContentItem {
  min-height: 100px;
  border: 2px solid;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
  transition: all .3s;
  justify-content: space-between;
  background: #F7F5F2;
  border-color: var(--color-main);
  display: flex;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.LeftWrap {
  width: 25px;
}
.RightWrap {
  width: calc(100% - 30px);
  padding: 8px;
}

.ScoreItem {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Title {
  font-size: 18px;
}

.TextWrap {
  width: 100%;
}

.Description {
  width: 100%;
}

.CopyWrap {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 10px;
}

.CopyText {
  font-size: 16px;
  margin-right: 10px;
}

.CopyIconWrap {
  display: flex;
  margin-bottom: 4px;
}

.CopyIcon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
}

.CheckedScore {
  color: var(--color-main) !important;
}

.CheckedCopy {
  color: white !important;
  font-size: 18px;
}

.CancelIconWrap {
  position: absolute;
  right: 3px;
  top: 3px;
  background: #6998AB;
  padding: 4px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}

.CancelIcon {
  font-size: 15px !important;
  color: white !important;
  font-weight: bold !important;
}

@media only screen and (max-width: $size-medium-tablet) {
  .Footer {
    display: block;
  }

  .CopyWrap {
    display: block;
  }
  .CopyText {
    font-size: 12px;
    margin-right: 10px;
  }

  .Title {
    font-size: 15px;
  }

  .Description {
    font-size: 12px;
  }

  .CopyIcon {
    img {
      width: 36px;
    }
  }

  .SaveBtnWrap {
    display: flex;
    justify-content: flex-end;
  }
}
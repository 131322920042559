@import "src/styles/variables";

.FilterWrap {
  padding: 10px 14px;
  display: flex;
  flex-wrap: wrap;
}

.FilterBox {
  height: 100px;
  border: 2px solid #82A284;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  background: white;
  border-radius: 5px;
  text-align: center;
  width: 200px;
  min-width: 200px;
  margin-bottom: 8px;
  margin-right: 4px;
  margin-left: 4px;
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
  }
}

.CheckBoxItem {
  font-size: 18px !important;
  font-weight: 500;
}

.IsActiveFilter {
  background: #D3E4CD;
}

.IsProcess {
  opacity: 0.7;
  cursor: unset !important;

  &:hover {
  }
}

@media only screen and (max-width: $size-large-tablet) {
  .FilterBox {
    font-size: 14px !important;
    font-weight: bold !important;
    height: 70px;
    padding: 4px;
  }

  .CheckBoxItem {
    font-size: 15px !important;
  }
}


@media only screen and (max-width: $size-small-desktop) {
  .FilterBox {
    font-size: 14px !important;
    font-weight: bold !important;
    height: 70px;
  }
}

@media only screen and (max-width: $size-landscape-tablet) {
  .FilterBox {
    font-size: 14px !important;
    font-weight: bold !important;
    height: 70px;
  }

  .CheckBoxItem {
    font-size: 15px !important;
  }
}

@media only screen and (min-width: $size-small-desktop) and (orientation: portrait) {
  .FilterBox {
    &:hover {
      background: #D3E4CD;
    }
  }
}

@import "src/styles/variables";

.Container {
  position: relative;
  padding: 30px;
}

.SpinWrap {
  position: absolute;
  top: 50%;
  right: 50%;
}

.ActionWrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.NewUserButton {
  font-size: 15px;
}

@media only screen and (max-width: $size-large-phone) {
  .Container {
    padding: 10px;
  }
}

.ProfileWrap {
  padding: 0 10px 0 5px;
  margin-right: 10px;
}

.LogoutText {
  text-align: center;
  color: var(--color-main);
  width: 80px;
  font-weight: 600;

  span {
    font-size: 18px;
    margin-right: 4px;
  }

  &:hover {
    cursor: pointer;
    color: var(--color-main);
  }
}
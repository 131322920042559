@import "src/styles/variables";

.DiseaseListWrap {
  margin: 20px;
}

.DiseaseClassName {
  width: 30px;
  writing-mode: vertical-lr;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.DiseaseWrap {
  display: flex;
  min-height: 200px;
  width: 100%;
}

.DiseaseItem {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.FilterBox {
  height: 100px;
  width: 200px;
  min-width: 200px;
  border: 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  background: white;
  border-radius: 5px;
  transition: all .2s;
  color: black;
  text-align: center;
  margin-top: 8px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

.NoData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #9d9d9d;
  width: 100%;
}


@media only screen and (max-width: $size-large-tablet) {
  .FilterBox {
    font-size: 14px !important;
    font-weight: bold !important;
    height: 70px;
  }

  .DiseaseWrap {
    min-height: 140px;
  }

  .NoData {
    font-size: 14px;
  }
}

@media only screen and (max-width: $size-small-desktop) {
  .FilterBox {
    font-size: 14px !important;
    font-weight: bold !important;
    height: 70px;
  }

  .DiseaseClassName {
    font-size: 15px;
  }

  .DiseaseWrap {
    min-height: 140px;
  }

  .NoData {
    font-size: 14px;
  }
}

@media only screen and (max-width: $size-landscape-tablet) {
  .FilterBox {
    font-size: 14px !important;
    font-weight: bold !important;
    height: 70px;
  }
}




@import "src/styles/variables";

.FormModal {
  height: 370px;
  @include FormModal;
}

.SpinWrap {
  margin: 100px;
  text-align: center;
}

.Footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.SelectInput {
  width: 100%;
}
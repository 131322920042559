.Container {
  width: 400px;
  padding: 10px;
  max-width: calc(100vw);
}

.HeaderWrap {
  display: flex;
  justify-content: flex-end;
}

.ClassSelection {
  width: 200px;

  div {
    border-radius: 15px !important;
  }
}

.DiseaseWrap {
  max-height: 200px;
  height: 200px;
  min-height: 200px;
  overflow-y: auto;
  margin-top: 15px;
}

.DiseaseItem {
  cursor: pointer;
  padding: 5px 10px;
  color: #282c34 !important;

  &:hover {
    background: var(--color-main);
    color: white !important;
  }
}

.Icon {
  color: var(--color-main) !important;
  margin-left: 6px;
}

.NoData {
  color: var(--gray-color-text);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.SpinWrap {
  height: 200px;
  justify-content: center;
  align-items: center;
  display: flex;
}

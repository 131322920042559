@import "../../styles/variables";

.Button {
  background: var(--color-background-button);
  color: white;
  border-radius: 20px;
  padding: 0 20px;
  height: 35px;
  font-weight: 500;
  font-size: 18px;
  border: none;
  cursor: pointer;

  &:hover {
    color: white;
    border: 1px solid var(--color-main);
  }
  &:focus {
    color: white;
    border: 1px solid var(--color-main);
  }
}

.Disabled {
  cursor: unset;
  opacity: 0.7;
}

@media only screen and (max-width: $size-large-phone) {
  .Button {
    font-size: 12px !important;
    padding: 0 15px;
    height: 30px;
  }
}

@import "src/styles/variables";

.FilterWrap {
  padding: 0 20px;
}

.CheckBoxItem {
  font-size: 18px !important;
  font-weight: 500;
  line-height: 20px;
  margin-right: 10px;
}

.IsActiveFilter {
  background: #D3E4CD;
}

.IsProcess {
  opacity: 0.7;
  cursor: unset !important;

  &:hover {
  }
}

@media only screen and (max-width: $size-large-tablet) {
  .CheckBoxItem {
    font-size: 15px !important;
  }
}


@media only screen and (max-width: $size-small-desktop) {
}

@media only screen and (max-width: $size-landscape-tablet) {
  .CheckBoxItem {
    font-size: 15px !important;
  }
}

@media only screen and (min-width: $size-small-desktop) and (orientation: portrait) {
}

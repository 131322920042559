//11@import "src/styles/colors";

.RegisterPasswordSuccessfully {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.RegisterPasswordSuccessfullyWrap {
  padding: 110px 90px !important;
  max-width: 740px;
  border-radius: 30px !important;
  box-shadow: var(--shadow);
  width: 100%;
  height: 740px;
  max-height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Form {
  height: 100%;
  width: 100%;
}

.LogoImage {
  display: flex;
  justify-content: center;

  img {
    width: 300px;
    height: auto;
  }
}

.SuccessText {
  color: var(--color-main);
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
}


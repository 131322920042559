
.TextParagraph {
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;

  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Message {
  font-size: 16px;
  white-space: nowrap;
  display: flex;

  span {
    flex: 1;
    white-space: nowrap;
    font-size: 16px;
  }
}
.Time {
  text-align: right;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
}

.MoreLessLink {
  color: var(--color-button-default);
  text-decoration: underline;
  cursor: pointer;
  margin-left: 4px;
}

.MessageText {
  white-space: pre-line;
  font-size: 14px;
}

.Visible {
  display: block !important;
}
@import "src/styles/variables";

.FilterWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.Filter {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.FirstFilter {
  display: flex;
}

.RightWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  align-items: flex-end;
}

.DiseaseBox {
  height: 100px;
  border: 2px solid #D0C9C0;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  background: white;
  border-radius: 5px;
  width: 200px;
  justify-content: space-around;
  padding: 10px;

  &:hover {
    cursor: pointer;
  }
}

.DiseaseName {
  font-size: 15px;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.LinkText {
  color: #398ab9;
}

.AddDiseaseIcon {
  margin-right: 6px;
  span {
    color: var(--color-main) !important;
  }
}

.CheckBoxItem {
  font-size: 16px;
  font-weight: 600;
}

.Disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.4;
}

.CopyButtonWrap {}

.CopyButton {
  font-size: 15px;
}

.CopyIcon {
  margin-right: 5px;
}

@media only screen and (max-width: $size-large-tablet) {
  .DiseaseBox {
    font-size: 14px !important;
    font-weight: bold !important;
    height: 80px !important;
  }
}

@media only screen and (max-width: $size-small-desktop) {
  .FilterWrap {
    display: block;
  }

  .Filter {
    display: flex;
    justify-content: space-between;
  }

  .DiseaseBox {
    font-size: 15px !important;
    font-weight: bold !important;
    height: 80px !important;
  }

  .SeverenessWrap {
    width: 250px;
    height: 80px;
  }

  .SeverenessName {
    font-size: 18px;
  }
  .BackToList {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
    font-size: 16px;
  }
}

@media only screen and (max-width: $size-landscape-tablet) {
  .DiseaseBox {
    font-size: 15px !important;
    font-weight: bold !important;
    height: 100px;
  }

  .CheckBoxItem {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: $size-small-tablet) {
  .Filter {
    display: block;
  }

  .RightWrap {
    height: auto;
    margin-top: 10px;
    align-items: flex-start;
  }
}

@media only screen and (max-width: $size-large-phone) {
  .Filter {
    display: block;
  }

  .RightWrap {
    height: auto;
    margin-top: 10px;
    align-items: flex-start;
  }

  .DiseaseBox {
    width: 100%;
  }

  .DiseaseName {
    width: calc(100% - 30px);
  }

  .CopyButton {
    font-size: 12px;
  }
}


//11@import "src/styles/colors";

.RegisterPasswordForm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.RegisterPasswordFormWrap {
  padding: 110px 90px !important;
  max-width: 740px;
  border-radius: 30px !important;
  box-shadow: var(--shadow);
  width: 100%;
  height: 740px;
  max-height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Form {
  height: 100%;
  width: 100%;
}

.LogoImage {
  display: flex;
  justify-content: center;

  img {
    width: 300px;
    height: auto;
  }
}

.Row {
  margin-top: 10px;
}

.ButtonWrap {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.ButtonCustom {
  padding: 0 50px !important;
  height: 40px;
  font-size: 18px;
}

.InputCustom {
  input {
    height: 35px !important;
    border-radius: 5px !important;
    border: 1px solid #D1D1D1 !important;
  }
}

.Error {
  color: var(--color-error);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.LoginLink {
  text-align: center;
  margin-top: 5px;
  color: var(--color-main) !important;
  text-decoration: underline;
}

.Text {
  color: var(--color-main);
  text-align: center;
}

.EmailName {
  font-weight: bold;
}



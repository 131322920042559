@import "src/styles/breakpoints";
@import "src/styles/colors";
.Wrap {
  background: var(--color-background-body);
  min-height: 100vh;
  .PaddingSidebar {
    padding-left: 90px;
    height: 100%;
  }
  .PaddingWrap {
    position: relative;
  }
  &.PaddingHeader {
    padding-top: 90px;
  }
}

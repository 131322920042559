//@import "/colors";
@import "src/styles/breakpoints";

$transition: cubic-bezier(0.17, 0.67, 0.42, 1.36);


@mixin ListItems {
  .DeleteIcon {
    color: var(--color-error) !important;
    cursor: pointer;
    font-size: 16px;
  }

  .EditIconIcon {
    color: var(--color-main) !important;
    cursor: pointer;
    font-size: 16px;
  }
}

@mixin FormModal {
  :global {
    .ant-modal-content {
      padding: 0;
    }

    .ant-modal-title {
      padding: 12px 14px;
    }

    .ant-modal-body {
      padding: 12px 14px;
    }
  }

  .Footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .Body {
    padding: 30px;
  }
  .SaveButton {
    width: auto;
    padding: 0 30px;
    line-height: 40px;
    font-size: 18px;
    font-weight: 600;
  }

  .SaveButtonWrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .Row {
    margin-top: 10px;
  }

  .InlineInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 32.5px;

    .Input {
      max-width: 190px;
      width: calc(100% - 180px) !important;
      margin-left: 10px;
      input {
        background: var(--color-white);
        font-size: 16px;
        line-height: 27px;
      }
    }
  }

  .Label {
    font-size: 15px;
    font-weight: 600;
  }

  .InputWrap {
    & > label {
      margin-bottom: 0;
    }

    .Input {
      min-width: 190px;
      width: 100%;
      max-width: 100%;

      input {
        background: var(--color-white);
        font-size: 16px;
        line-height: 27px;
      }
    }
  }

  .AttachFileInput {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: var(--color-icon);
    cursor: pointer;

    span {
      text-decoration: underline;
    }
  }

  .SettingLink {
    display: flex;
    align-items: center;
    margin-top: 20px;
    color: var(--color-grey-text);

    &:hover {
      cursor: pointer;
      color: var(--color-blue-text);
    }

    .SettingLinkText {
      margin-left: 2px;
      font-size: 15px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .NoPaddingBottom {
    padding-bottom: 0 !important;
  }

  .Error {
    color: var(--color-error);
    font-size: 14px;
    font-weight: bold;
  }

  @media only screen and (max-width: $size-medium-tablet) {
    .Row {
      .InputWrap {
        .Label {
          font-size: 12px;
        }
        .Input {
          input {
            font-size: 14px;
          }
        }
      }
    }

    .SettingLink {
      .SettingLinkText {
        font-size: 12px;
      }
    }

    .Body {
      padding: 12px;
    }
  }
}

@import "src/styles/variables";

.ListItems {
  @include ListItems;
}

.NameWrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    .SwapIconWrap {
      display: block;
    }
  }
}

.SwapIconWrap {
  display: none;
}

.SwapIcon {
  font-size: 18px;
  font-weight: bold;
  color: var(--color-main) !important;
  cursor: pointer;
}
@import "src/styles/variables";

.Container {
  padding: 20px 50px;
}

.ContentItem {
  position: relative;
  height: 200px;
  border: 2px solid;
  font-size: 20px;
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  padding: 10px;
  transition: all 0.3s;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
}

.ContentWrap {
  padding: 15px;
  height: calc(100vh - 215px);
  overflow-y: auto;
  border: 1px solid #87a7b3;
  border-top: none;
}

.Score {
  position: absolute;
  right: 10px;
  top: 5px;
  font-weight: bold;
  font-size: 24px;
}

.TabsWrap {
  margin-top: 15px !important;
}

.Chart {
  width: 100px;
  height: 40px;
}

.Tab {
  width: calc(100% / 6);
}

.TabName {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border: 1px solid;
  padding: 8px 16px;

  span {
    font-size: 15px;
    font-weight: 500;
    color: white;
  }
}

.ActiveTab {
  border: 2px solid;
  border-bottom: none;
  background: white !important;
  span {
    color: var(--color-main);
  }
}

.Description {
  font-size: 16px;
  color: #7f7c82;
  font-weight: 400;
}

.SpinWrap {
  margin-top: 100px;
  text-align: center;
}

.NoData {
  color: var(--gray-color-text);
  margin-top: 100px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  width: 100%;
  margin-bottom: 100px;
  justify-content: center;
}

@media only screen and (max-width: $size-landscape-tablet) {
  .Container {
    padding: 15px 20px;
  }

  .Chart {
    width: 80px;
    height: 30px;
  }
}

@media only screen and (max-width: $size-small-desktop) {
  .Container {
    padding: 15px 20px;
  }

  .Chart {
    width: 50px;
    height: 20px;
  }

  .TabName {
    span {
      margin-bottom: -6px;
    }
  }
}

@media only screen and (max-width: $size-large-tablet) {
  .Container {
    padding: 15px 20px;
  }

  .Chart {
    width: 40px;
    height: 20px;
  }

  .TabName {
    span {
      margin-bottom: -6px;
    }
  }
}

@media only screen and (max-width: $size-large-phone) {
  .Container {
    padding: 15px 20px;
  }

  .Chart {
    width: 40px;
    height: 20px;
  }

  .TabName {
    span {
      margin-bottom: -6px;
    }
  }

  .Title {
    font-size: 15px;
  }

  .Description {
    font-size: 12px;
  }
}

@media only screen and (max-width: $size-tiny-tablet) {
  .Chart {
    display: none;
  }
}

.DiseaseContainer{
  position: relative;
  width: 100%;
  height: 100vh;

}

.SpinWrap {
  position: absolute;
  top: 50%;
  right: 50%;
}

.HorizontalFilter {
  overflow: auto;
  width: 100%;
}

.DiseaseListWrap {
  :global {
    .Pane1 {
      background-color: #A7BBC7;
    }
    .Pane2 {
      overflow: auto;
      background-color: #FAF3F3;
    }
  }
}

.VerticalFilterWrap {
  background-color: #A7BBC7;
  overflow: auto;
  height: 100%;
}
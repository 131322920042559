:root {
  --color-background-body: #FAF3F3;
  --color-background-button: #5584ac;
  --color-main: #406882;
  --color-error: #CC0501;

  --gray-color-text: #B2B1B9;

  --tooltip-background: #9BA4B5;

}

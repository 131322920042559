@import "src/styles/variables";

.Container{
  padding: 20px 30px;
  height:  calc(100vh - 70px);
}

.ContentWrap {
  padding: 15px;
  height: calc(100vh - 250px);
  overflow-y: auto;
  border: 1px solid var(--gray-color-text);
  border-top: none;
}

.SpinWrap {
  position: absolute;
  top: 50%;
  right: 50%;
}

.TabsWrap {
  margin-top: 15px !important;
}

.Chart {
  width: 100px;
  height: 40px;
}

.TabName {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border: 1px solid;
  padding: 8px 16px;

  span {
    font-size: 15px;
    font-weight: 500;
    color: white;
  }
}

.ActiveTab {
  border: 2px solid;
  border-bottom: none;
  background: white !important;
  span {
    font-weight: bold;
  }
}

.NoData {
  color: var(--gray-color-text);
  margin-top: 100px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: $size-landscape-tablet) {
  .Container {
    padding: 15px 20px;
  }

  .Chart {
    width: 80px;
    height: 30px;
  }
}

@media only screen and (max-width: $size-small-desktop) {
  .Container {
    padding: 15px 20px;
  }

  .Chart {
    width: 50px;
    height: 20px;
  }

  .TabName {
    span {
      margin-bottom: -6px;
    }
  }
}


@media only screen and (max-width: $size-large-tablet) {
  .Container {
    padding: 15px 20px;
  }

  .Chart {
    width: 40px;
    height: 20px;
  }

  .TabName {
    span {
      margin-bottom: -6px;
    }
  }
}

@media only screen and (max-width: $size-large-phone) {
  .Container {
    padding: 15px 20px;
  }

  .Chart {
    width: 40px;
    height: 20px;
  }

  .TabName {
    span {
      margin-bottom: -6px;
    }
  }
}


@media only screen and (max-width: $size-tiny-tablet) {
  .Chart {
    display: none;
  }
}
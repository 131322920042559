@import "../../styles/variables";

.AdministrationWrap {
  position: relative;
}

.Header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background: white;
  z-index: 2;
}

.LeftWrap {
  display: flex;
}

.MenuButton {
  width: 85px;
  height: 70px;
  background: white;
  box-shadow: -12px -15px 25px 12px #d1d1d1;
  text-align: center;

  img {
    width: 60px;
    height: 60px;
  }
}

.Breadcrumb {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.ToggleIcon {
  display: flex;
  justify-content: center;
  margin-top: -6px;
  padding-bottom: 4px;
  color: var(--color-grey-button-edit-text);
}

.Menu {
  position: fixed;
  top: 70px;
  left: 0;
  width: 85px;
  z-index: 2;

  ul {
    height: 100vh !important;

    li {
      height: 70px !important;
      line-height: 20px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      text-align: center;
      margin: 0 !important;
    }
  }
}

.MenuIcon {
  span {
    font-size: 20px !important;
  }
}

.ContentWrap {
  margin-left: 85px;
}

.ContentWrapNoCollapsed {
  margin-left: 0;
}

@media only screen and (max-width: $size-large-phone) {
  .Menu {
    width: 65px;
  }

  .MenuText {
    font-size: 12px !important;
  }

  .MenuButton {
    width: 65px;
    min-width: 65px;

    img {
      width: 50px;
      height: 50px;
    }
  }

  .ToggleIcon {
    margin-top: -2px;
  }

  .ContentWrap {
    margin-left: 65px;
  }

  .ContentWrapNoCollapsed {
    margin-left: 0;
  }
}

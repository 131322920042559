@import "src/styles/variables";

.FormModal {
  @include FormModal;
}

.Footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.PositionWrap {
  display: block !important;
}